import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>ATL Marathon Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="ATL Marathon Race Report" />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/atl-marathon-race-report/meta.jpg"
        />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/atl-marathon-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">ATL Marathon Race Report</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">February 27, 2022</p>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            Whether it’s video games or endurance sports, there’s a part of me
            that’s a completionist. If there’s a progress bar to complete, I
            want to finish it at my core.
          </p>

          <p>
            The Atlanta Track Club has two series of races. The first is Triple
            Peach, which I’ve completed twice before. In 2019 and 2021, I ran
            the Peachtree Road Race, the ATL 10 Miler, and the Thanksgiving Day
            Half Marathon. For doing so, I got a Triple Peach medal and some
            kind of cold-weather clothing item. I received a wooly hat in 2019
            and a pair of gloves in 2021.
          </p>
          <p>
            If you complete the Triple Peach and then run the Atlanta Marathon
            the following February, you achieve Ultimate Peach, something I had
            yet to achieve. The Atlanta Marathon is also the only major ATC race
            I’ve never run. The completionist in me couldn’t resist signing up.
          </p>

          <p>
            Unfortunately, during the weeks leading into the marathon, I had
            been battling some lateral foot pain during long runs in my left
            foot. I’d have no issues for the first 10 miles, but then things
            would get painful. For all the miles I’ve run over the years, I’ve
            never had pain like this.
          </p>

          <p>
            The week before the race, I had Chase look at things. He poked
            around, but we couldn’t recreate that pain, even a little bit. The
            only idea we had was that it was time for new shoes. I track my
            miles while using the same Brooks shoe model every time (they’re the
            best company!), and I usually run them to about 350 before replacing
            them. These particular shoes were at about 275. As much as I hated
            the idea of something new the week of a race, I upgraded shoes
            before heading off to Atlanta.
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Pre-Race</h1>
          <p>
            Remember what I said about being a completionist? The Atlanta Track
            Club brands this as “Atlanta’s Marathon Weekend.” In addition to the
            marathon on Sunday, they had several events on Saturday, including a
            5k.
          </p>
          <p>
            I wanted to do roughly a 5k shakeout run the day before, and what
            better place than on the actual course? I inevitably signed up for
            the Saturday 5k.
          </p>
          <p class="pb-4">
            Number pickup was in the Georgia Aquarium where I haven’t been in
            about a decade. I tend to be in and out quickly at the race expos.
            However, this number pickup was different.
          </p>
          <div className="full-image">
            <figure class="figure">
              <img
                src="/images/atl-marathon-race-report/number-pickup.jpg"
                alt=""
              />
              <figcaption class="figure-caption">
                Seriously, how cool is this?
              </figcaption>
            </figure>
          </div>
          <p>
            I ended up watching the fish for about 30 minutes. I’d just driven
            straight from Nashville to the Aquarium, so I enjoyed the time out
            of the car!
          </p>
          <p>
            One big plus of doing the 5K is that it would give me a dry run for
            the marathon – what time to wake up, where to park, etc. It’s been a
            long time since I’ve been to Centennial Olympic Park, so this was as
            valuable as the shakeout run to stay loose.
          </p>
          <p class="pb-4">
            Spoiler alert: describing the 5K as a “dry run” ended up being
            accurate in more ways than one. On Saturday morning, the weather was
            perfect: a few clouds, a light breeze, and in the 40s.
          </p>
          <div className="full-image">
            <figure class="figure">
              <img
                src="/images/atl-marathon-race-report/5k-sunrise.jpg"
                alt=""
              />
              <figcaption class="figure-caption">
                Good morning, A-Town
              </figcaption>
            </figure>
          </div>
          <div className="full-image">
            <figure class="figure">
              <img
                src="/images/atl-marathon-race-report/5k-selfie.jpg"
                alt=""
              />
              <figcaption class="figure-caption">
                Look how comfortable and dry I am!
              </figcaption>
            </figure>
          </div>
          <p>
            The 5K was uneventful, but the finish line was VERY EVENTFUL. Kudos
            to Publix for BBQ, mac & cheese, a sausage, and a roll after only
            running 3 miles. It’s better food than every race or triathlon I’ve
            ever completed. Publix is the best.
          </p>
          <p class="pb-4">
            A sentence I never thought I’d write: I ate my BBQ in front of the
            same fountains where I got engaged to Katy about eleven years
            earlier.
          </p>
          <div className="full-image">
            <figure class="figure">
              <img src="/images/atl-marathon-race-report/5k-bbq.jpg" alt="" />
              <figcaption class="figure-caption">
                Publix: Where Providing BBQ to Runners is a Pleasure
              </figcaption>
            </figure>
          </div>
          <p>
            I went home, relaxed, ate a tasty dinner, and got ready for the
            marathon. It’s always unnerving how fast it is to prepare for runs
            compared to triathlon. I always feel like I forget something.
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Morning</h1>
          <p>
            I woke up at about 4:15 a.m. I’d planned to get up at 5, but I guess
            my subconscious wanted to check the radar.
          </p>
          <p class="pb-4">Uh oh.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/atl-marathon-race-report/radar.jpg" alt="" />
              <figcaption class="figure-caption">
                At least it wasn't snow heading into ATL?
              </figcaption>
            </figure>
          </div>

          <p class="pb-4">
            Not only was rain coming, but the temperature would be falling
            during the race! I figured it was futile with this much rain coming,
            but I put a poncho over my race gear and waited to start. A few
            people had ponchos, some people had more durable rain gear, and some
            people were just in trash bags.
          </p>

          <div className="two-image">
            <img src="/images/atl-marathon-race-report/start1.jpg" alt="" />
            <img src="/images/atl-marathon-race-report/start2.jpg" alt="" />
          </div>

          <p>Let's go!</p>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Race</h1>
          <p>
            <strong>Time:</strong> 5:04:10
            <br />
            <strong>Distance:</strong> 26.2 miles
            <br />
            <strong>Pace:</strong> 11:30/mile
            <br />
            <strong>AG:</strong> 100/105
            <br />
            <strong>Men:</strong> 626/716
            <br />
            <strong>Overall:</strong> 876/1031
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Relentless rain
            <br />
            <strong>Course conditions:</strong> Rivers of water over asphalt and
            concrete
            <br />
            <strong>Terrain:</strong> Hilly
          </p>
          <p>
            I had a hard time deciding how to approach this race. I didn’t want
            to go too hard and impact triathlon training. Gulf Coast 70.3 is
            only nine weeks away! I decided to take the race with the same
            conservative approach I did for the marathon during Ironman
            Chattanooga: throttle things down to 11-minute miles and walk
            through each aid station.
          </p>

          <p>
            The first miles felt slow and tedious at this pace. Unlike in
            Chattanooga, I hadn’t just biked 116 miles! I felt fresh and wanted
            to go quicker, especially with just a drizzle falling so far.
          </p>

          <p>
            I stayed true to the plan and held myself to no faster than eleven
            minutes. I had two goals with this race: finish in under five hours
            and, more importantly, stay injury-free. If I stuck with that pace,
            I’d clock in around 4:45.
          </p>

          <p>
            Around mile 3, the heavens opened, and the deluge didn’t stop for
            four straight hours.
          </p>

          <p>
            By mile 6, as we approached Piedmont Park, everyone was drenched
            head to toe. I didn’t just have damp shoes at this point; I had
            completely waterlogged shoes. The rain wasn’t just streaming down in
            the gutters; it was rushing down the road on every hill along the
            course.
          </p>
          <p>
            Besides my water galoshes, I physically felt great! Minus a
            cumbersome bathroom break at an aid station, I had kept to that
            11-minute pace through the first 10 miles. I’d planned to do a bit
            of a run/walk mix around mile 10 as I left Piedmont Park. I’ve
            sprinted up this hill before in the 10 Miler and the half marathon,
            but I wanted to pull back here to save some matches. I could still
            finish under five hours if I took a few extra minutes here.
          </p>
          <p>
            I got to the top of the hill and resumed the frustratingly
            conservative pace. 10 miles down, 16 to go. Meanwhile, the rain.
            Just. Wouldn’t. Stop.
          </p>
          <p class="pb-4">
            I’ve done a lot of Atlanta Track Club races over the years. I’ve
            never seen these signs at aid stations.
          </p>
          <div className="full-image">
            <figure class="figure">
              <img
                src="/images/atl-marathon-race-report/potentially-dangerous.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            I don’t know if I’d call the weather potentially dangerous, but it
            did fuck up my entire plan.
          </p>

          <p>
            When the day began, I felt prepared for anything that might happen.
            Band-aids for possible blisters? Body glide for potential chafing? A
            couple of Tylenol wrapped in a small piece of shrink wrap just in
            case? Check, check, and check.
          </p>

          <p>
            I’m not being dramatic or embellishing when I say the rain literally
            destroyed all these things. The band-aids in just a paper wrapping
            stood no chance. The disposable container of body glide? It was made
            of cardboard and became a mushy mess that leaked on everything. The
            Tylenol? They were liquified! All I found was a wet piece of shrink
            wrap.
          </p>

          <p>
            The Gu gels I brought were the only things the rain didn’t destroy.
            I ate one around mile 11. That’s when I discovered what had happened
            to everything else in my back pockets!
          </p>

          <p>
            Around mile 12, my bib started falling off. The holes to attach it
            had torn. I stopped, poked some new holes, and kept going. Those
            lasted for about five minutes before tearing apart again. Not only
            that, the ink had started coming off as well. I wanted to try to
            protect the timing chip from getting lost if the bib fell apart
            entirely. So I pulled the chip off and put it in my pocket. This
            weather! LOL
          </p>

          <p>
            Over the first 13 miles, the course felt a bit crowded. However,
            when I got to the half-marathon/marathon split, whoa. About 90% of
            the runners were veering left to turn into Centennial Olympic Park
            to finish the race. Only a few of us were turning right towards
            Mercedes-Benz Stadium.
          </p>

          <p>
            While I ran across the bridge at MBS, I realized I had quite the
            chafing and blister situation developing. Unfortunately, there
            wasn’t much I could do about it. Everything I brought to treat it
            was gone. In hindsight, I’m surprised it took 14 miles to become a
            problem. I was soaking wet for ~10 miles now.
          </p>

          <p>
            The worst part had to be that my wet, heavy shoes were rubbing my
            ankles with each step. The backs of both ankles were already a bit
            bloody, and I still had 12 miles to go. At least the rain was
            washing the blood away!
          </p>

          <p>
            It never crossed my mind that any of this would preclude me from
            finishing, but I kept having to stop briefly. First, it was my bib
            falling apart. Then, I kept needing to pull my socks up to cover the
            backs of my ankles. Also, I kept adjusting my shorts to keep the
            blister/chafing situation at bay as best I could. With all these
            stops, finishing in under five hours was slipping away.
          </p>

          <p>But! That’s okay!</p>

          <p>
            During miles 15-20, I think I went through a Stockholm syndrome
            experience with the rain. I started wanting it to rain harder! Even
            if I crushed my goal time, that wouldn’t be what I told friends
            about or wrote in this race report. It would be all about the rain.
          </p>

          <p>
            I always try to do it anyway, but I made sure to thank every ATC
            volunteer and every Atlanta cop out on the route. What’s worse than
            running in the rain? Handing out Powerade in the rain. Or directing
            traffic in the rain.
          </p>

          <p>
            It’s a bit skewed when I look at my pace from here until around mile
            24. Strava doesn’t know how to account for fifteen-second stops to
            adjust your clothing. Minus the chafing and blisters, I felt pretty
            good physically. It was just a battle against the conditions.
          </p>

          <p>
            The final 2 miles take you from Turner Field to Oakland Cemetary and
            then back to Centennial Olympic Park. As I ran up Capital Avenue,
            something magical happened. THE RAIN STOPPED!
          </p>

          <p>
            I realize this is a strange statement about this point of a
            marathon, but the final 2 miles were honestly a genuinely wonderful
            experience. I felt so happy. I ditched the poncho that I’d been
            wearing the whole race. I thought back to where things were just a
            year ago. Vaccines were hard to come by. Racing hadn’t returned yet.
            I wasn’t in marathon shape either. A year later, I’m vaxxed,
            boosted, healthy, and about to complete a marathon!
          </p>

          <p>
            I remained elated despite glancing at my watch and seeing I wouldn’t
            hit my goal time. There will be other marathons to get that (or an
            even better time and hopefully without a monsoon!) But I like to
            think I’ll never run one in conditions worse than this.
          </p>

          <p>
            The finish line is so cool. You finish in front of the Olympic
            fountains on the bricks. The shirt I wore was a Falcons training
            camp shirt that says “RISE UP” in giant red letters. As I finished,
            the race announcer yelled out something about a Falcons fan rising
            up in spite of the weather.
          </p>
          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Postrace</h1>

          <p>
            The same fantastic post-race food by Publix awaited me again today.
            I grabbed my BBQ, my marathon medal, and then my Ultimate Peach
            medal.
          </p>

          <p class="pb-4">
            I’m not usually into staged race photos, but I had one in mind for
            this race.
          </p>

          <div className="full-image">
            <figure class="figure">
              <img
                src="/images/atl-marathon-race-report/brick-medals.jpg"
                alt=""
              />
              <figcaption class="figure-caption">
                Note the lack of rain and standing water in this photo. 🙃
              </figcaption>
            </figure>
          </div>

          <p>
            I like to believe in better conditions that I would have finished
            sub-5 with no issues. In hindsight, I also approached the day far
            more conservatively than I was capable of. During the final 2 miles,
            I thought of Susie, who always tells me I have more in the tank than
            I realize. She’s always right. In hindsight, if I hadn’t been as
            conservative early on, I would have finished sub-5. On the flip
            side, the primary goal was to stay injury-free, and I achieved that!
            (Unless you count all the blisters, and chafing in places that I
            didn’t even realize could chaf.)
          </p>

          <p>
            But goal time aside, I will remember this race quite fondly. Even in
            the South, it’s uncommon to get that kind of relentless rain so
            early in the morning. It’s unlikely I’ll ever race in worse
            conditions. As much as I love the photo of my medals with my brick,
            the one that sums up the day best is the state of my race bib after
            hours of rain.
          </p>

          <p class="pb-4">It was a fantastic day.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/atl-marathon-race-report/number.jpg" alt="" />
              <figcaption class="figure-caption">
                I usually try to take good care of my bibs so they look good in
                this book I have for them. Not today.
              </figcaption>
            </figure>
          </div>

          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Next Steps</h1>
          <p>
            I’m officially into triathlon season now, with Gulf Coast 70.3 only
            nine weeks away!
          </p>

          <p>
            Leading into the race, I remember thinking this would probably be my
            only standalone marathon. I’d like to do another full Ironman
            sometime, which obviously will include a marathon. But I didn’t have
            any other marathons on my mind.
          </p>

          <p>
            However, I have a little bit of unfinished business. A sub-5 hour
            marathon (or better) is something I’d like to get to. I don’t know
            if I’ll run Atlanta again, but as I walked to my car, I started
            pondering the Nashville or Chattanooga marathons in 2023.
          </p>

          <p class="pb-4">
            After a shower and some dry clothes, there was only one thing left
            to do with the weekend: beers at Taco Mac!
          </p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/atl-marathon-race-report/taco-mac.jpg" alt="" />
            </figure>
          </div>

          <div class="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
